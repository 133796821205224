import React, {useContext, useEffect, useState} from 'react'
import {getAttributes, getSession, isContributor} from "../../services/cognito";
import {UserDispatchContext} from "../../contexts/UserContext";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import {useTranslation} from "react-i18next";
import {AppRoute} from "../../const";
const Redirect = () => {


    const navigate = useNavigate();
    let [searchParams] = useSearchParams();

    const dispatch = useContext(UserDispatchContext);
    const [signInErrors, setSignInErrors] = useState([])

    const [loading, setLoading] = useState(false)
    const { i18n , t} = useTranslation();

    const [path, setPath] = useState(null)

    const lang = i18n.language


    useEffect(()=>{

        if(searchParams.get('code'))
        {
            setLoading(true)
            const authObject = {
                JwtToken : '',
                Expiration : 0,
                FamilyName : '',
                GivenName : '',
                PhoneNumber : '',
                Email : '',
                UserId : '',
                Newsletter : 0,
                RememberExpiration : 0
            }
            getSession().then((session)=>{
                authObject.JwtToken = session.tokens.idToken.toString()
                authObject.Expiration  = session.tokens.idToken.payload.exp
                try{
                    return getAttributes().then((attributes)=>{
                        authObject.FamilyName =  attributes.family_name
                        authObject.GivenName =  attributes.given_name
                        authObject.PhoneNumber =  attributes["custom:phoneNumber"]
                        authObject.Newsletter =  parseInt(attributes["custom:newsletter"],10)
                        authObject.UserId =  attributes.sub
                        authObject.Email =  attributes.email

                        return authObject
                    })
                }
                catch (e) {
                    throw e
                }
            }).then((authObject)=>{
                try{
                    return isContributor().then((res)=>{
                        authObject.isContributor = res
                        return authObject
                    })
                }
                catch (e) {
                    throw e
                }
            }).then((authObject)=>{
                dispatch({
                    type: 'signIn',
                    response:authObject
                })


                setPath("./../"+lang+t(AppRoute.SignUp))


            }).catch((e)=>{
                setSignInErrors([e.toString()])
                setLoading(false)
            })
        } else {
            dispatch({
                type: 'signOut'
            })
            setPath("/"+lang+t(AppRoute.SignIn))
        }




    },[searchParams,t,navigate,lang, dispatch, setPath])

    if(path) {
        return <Navigate to={path} replace />;
    }

    return (
        <>
            {signInErrors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {signInErrors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
        </>


    )
}
export default Redirect