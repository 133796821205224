import React from 'react'
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import SignInWithFacebook from "../SignInWithFacebook/SignInWithFacebook";
import {Avatar, Box, Container, CssBaseline} from "@mui/material";
import Diversity3Icon from '@mui/icons-material/Diversity3';
const SignInFormSocial = () => {

    const [t] = useTranslation();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <Diversity3Icon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('forms.signInForm.labels.signInWithSocial')}
                </Typography>


            <SignInWithFacebook/>
        </Box>
        </Container>

    )
}
export default SignInFormSocial