import jwt_decode from "jwt-decode";
import { deleteUser, signInWithRedirect,signIn,fetchAuthSession,fetchUserAttributes, signOut,updateUserAttributes,signUp,resetPassword,confirmResetPassword,confirmSignUp     } from 'aws-amplify/auth';

import { Amplify } from 'aws-amplify';
const userPoolId = process.env.REACT_APP_USERPOOL_ID
const clientId = process.env.REACT_APP_CLIENT_ID
const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN


Amplify.configure({
    Auth: {
        Cognito: {
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: userPoolId,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: clientId,


            allowGuestAccess: true,

            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            signUpVerificationMethod: 'code', // 'code' | 'link'

            loginWith: {
                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: cognitoDomain,
                    scopes: [
                        'phone',
                        'email',
                        'profile',
                        'openid',
                        'aws.cognito.signin.user.admin'
                    ],
                    redirectSignIn: ['http://localhost:3000/Redirect/','https://www.eliseiantiques.com/Redirect/','https://www.anticariatelisei.ro/Redirect/'],
                    redirectSignOut: ['http://localhost:3000/Logout/','https://www.eliseiantiques.com/Logout/','https://www.anticariatelisei.ro/Logout/'],
                    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                }
            }
        }
    }
});

export async function isContributor() {
    return new Promise(function (resolve, reject) {
        return getSession().then((session)=>{
            try {
                var res = true
                if (!session) res = false
                else{
                    const token = session.tokens.accessToken.toString()
                    const decoded = jwt_decode(token)

                    if (decoded['cognito:groups'] && decoded['cognito:groups'].length > 0) {
                        res = decoded['cognito:groups'].find((group) => group === "MarketPlace" || group === "admin") !== undefined

                    }

                }
                resolve(res)
            }
            catch (e) {
                reject(e)
            }
            })
        }
    )
}

export async function getSession() {
    return await fetchAuthSession();
}

export async function signUpUserWith(email, password, familyName, givenName, phone, newsletter, seller) {

    return await signUp({
        username: email,
        password: password,
        options: {
            userAttributes: {
                family_name: familyName,
                given_name: givenName,
                "custom:phoneNumber": phone, // E.164 number convention
                "custom:seller": seller,
                "custom:newsletter" : newsletter,
            },
        }
    })
}

export async function verifyCodeFor(email, code) {

    return new Promise(function (resolve, reject) {
        return confirmSignUp({
            username: email,
            confirmationCode: code,
        }).then(()=>{
            resolve('SUCCESS')
        }).catch((err)=>{
            reject(err)
        })
    }).catch((err) => {
        throw err
    })
}

export async function signInWith(username, password) {
    try {
        const { isSignedIn } = await signIn({ username, password });
        return isSignedIn
    } catch (error) {
        throw error
    }
}

export function signOut2(success, failure) {
    return getSession().then((r)=>{
        if(r) {
            return signOut().then(s=>success(s)).catch(e=>failure(e))
        }
    }).catch((e)=>{
        failure(e)
    })
}

export async function getAttributes() {
    return fetchUserAttributes()
}

export async function updateAttributeList(data) {
    return updateUserAttributes({
            userAttributes: {
                "family_name": data.familyName,
                "given_name" : data.givenName,
                "custom:newsletter" : data.newsletter?"1":"0",
                "custom:phoneNumber" : data.phone ? data.phone : "",
            }
        });
}

export async function forgotPasswordFor(email) {



    return new Promise(function (resolve, reject) {
        return resetPassword({
            username: email
        }).then((output)=> {
            const { nextStep } = output;
            switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
            const codeDeliveryDetails = nextStep.codeDeliveryDetails;
            resolve(codeDeliveryDetails)
            console.log(
                `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
            );
            // Collect the confirmation code from the user and pass to confirmResetPassword.
            break;
        case 'DONE':
            console.log('Successfully reset password.');
            break;
            default:
                    throw Error("invalid type")
        }
        }).catch((err)=>reject(err));


    }).catch((err) => {
        throw err
    })
}

export async function newPasswordFor(email, code, newPassword) {
    return new Promise(function (resolve, reject) {
        return confirmResetPassword({
            username: email,
            confirmationCode: code,
            newPassword: newPassword,
        }).then(()=>{
            resolve('SUCCESS')
        }).catch((err)=>{
            reject(err)
        })
    }).catch((err) => {
        throw err
    })
}



export async function refreshToken() {
    return new Promise(function (resolve, reject) {
        return fetchAuthSession().then((result)=>{
            resolve({
                accessToken: result.accessToken ,
                idToken: result.idToken,
            })
        }).catch((err)=>{
            reject(err)
        })
    })
}

export async function signInWithFacebook() {
    return signInWithRedirect({provider: "Facebook"})
}

export async function removeAccount() {
    return deleteUser()
}
